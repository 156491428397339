import { postBodyRequest, getRequest } from '@/utils/axios'

export function getPage (params) {
  return postBodyRequest('/market/crm/group/page', params)
}
// 新建关联客户
export function addData (params) {
  return postBodyRequest('/market/crm/group/new', params)
}
// 删除
export function deleteCustomer (params) {
  return postBodyRequest('/market/crm/group/del', params)
}
// 额外添加
export function customerAdd (params) {
  return postBodyRequest('/market/crm/group/add', params)
}
// 解除关联
export function removeCustomer (groupId,params) {
  return postBodyRequest(`/market/crm/group/termination/${groupId}`, params)
}
// 获取一组客户信息
export function getCustomerData (groupId,params) {
  return getRequest(`/market/crm/group/termination/${groupId}`, params)
}